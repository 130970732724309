import React from 'react'

export const Landing = () => {
  return (
    <div>
        
    
    <div className="pb-16 pt-20 text-center lg:pt-32">
        <div className='flex justify-center'>
        <svg xmlns="http://www.w3.org/2000/svg" 
            data-name="Layer 1" 
            width="350"  
            viewBox="0 0 795.30964 524.87772" >
                <circle cx="229.82018" cy="128.93492" r="125" fill="#3057E3"/>
                <path d="M741.59259,225.54924c-.84172-3.30377-3.58023-5.77558-6.43582-7.63822s-5.99805-3.35964-8.43669-5.74274c-2.438-2.383-4.07784-6.00667-2.946-9.22263,1.37576-3.90855,5.96032-5.47043,9.96791-6.5242a1077.20106,1077.20106,0,0,0-110.43018-6.16059,6.64252,6.64252,0,0,0-11.9883,3.96116V312.78577h13.32177V239.95414q47.46369.115,94.928.22994c5.12316.01236,10.45382-.02025,15.05984-2.264C739.23981,235.67644,742.85777,230.51417,741.59259,225.54924Z" transform="translate(-202.34518 -187.56114)" fill="#ccc"/>
                <path d="M815.14658,438.01041V398.0451h30.64007a41.29722,41.29722,0,0,0,41.29748-41.29741v-.00008h-233.131a18.65051,18.65051,0,0,1,18.65047-18.65047H835.12923v-4.63636c0-15.83294-10.96053-28.66807-24.4807-28.66807H608.09324c-31.19688,0-59.69949,20.09413-74.40157,51.9549H501.0722a10.65616,10.65616,0,0,0-7.41544,3.00285l-20.62727,19.98265a10.65735,10.65735,0,0,0,7.41543,18.312h25.6366v39.96531H388.30745a10.65761,10.65761,0,0,0-7.96574,3.57705l-23.68322,26.6433a10.65764,10.65764,0,0,0,7.96575,17.738H437.8699a10.65626,10.65626,0,0,1,9.03513,5.006l12.85342,20.55093a10.65766,10.65766,0,0,1-8.21357,16.27713l-158.864,12.28946a10.65719,10.65719,0,0,0-8.95967,6.39557l-33.64073,77.7938a10.65736,10.65736,0,0,0,9.78188,14.88747H790.67563a213.55436,213.55436,0,0,0,38.76518-3.53453c94.76865-17.48621,162.8853-101.25578,162.8853-197.62411v-.00008ZM546.04683,398.0451H775.18127v39.96531H546.04683Zm333.04424,85.25941a42.62941,42.62941,0,0,1-42.62966,42.62958H492.75975V525.934a42.62983,42.62983,0,0,1,42.62967-42.62959H879.09107Z" transform="translate(-202.34518 -187.56114)" fill="#3f3d56"/>
                <path d="M823.13964,504.61926H538.05377a6.66872,6.66872,0,0,0-6.66088,6.66088v15.98612H829.80053V511.28014A6.66873,6.66873,0,0,0,823.13964,504.61926ZM594.0052,507.28361h31.43756v17.3183H594.0052Zm-2.66435,17.3183H559.90329v-17.3183h31.43756Zm36.76626-17.3183H659.544v17.3183H628.10711Zm34.10126,0h31.43691v17.3183H662.20837Zm34.10126,0h31.43755v17.3183H696.30963Zm34.10191,0h31.43755v17.3183H730.41154Zm34.10191,0H795.951v17.3183H764.51345Zm-230.45621,3.99653a4.0009,4.0009,0,0,1,3.99653-3.99653h19.18517v17.3183h-23.1817Zm293.07893,13.32177H798.61536v-17.3183h24.52428a4.00089,4.00089,0,0,1,3.99653,3.99653Z" transform="translate(-202.34518 -187.56114)" fill="#3f3d56"/><rect x="584.82569" y="129.22117" width="17.3183" height="71.93756" fill="#3f3d56"/>
                <path d="M486.09887,643.16566H206.34171a3.99653,3.99653,0,1,1,0-7.99306H486.09887a3.99653,3.99653,0,1,1,0,7.99306Z" transform="translate(-202.34518 -187.56114)" fill="#ccc"/>
                <path d="M362.20641,667.14484H246.307a3.99653,3.99653,0,1,1,0-7.99306H362.20641a3.99653,3.99653,0,0,1,0,7.99306Z" transform="translate(-202.34518 -187.56114)" fill="#ccc"/>
                <path d="M873.76237,643.16566H594.0052a3.99653,3.99653,0,1,1,0-7.99306H873.76237a3.99653,3.99653,0,1,1,0,7.99306Z" transform="translate(-202.34518 -187.56114)" fill="#ccc"/>
                <path d="M701.91154,667.14484H422.15438a3.99653,3.99653,0,1,1,0-7.99306H701.91154a3.99653,3.99653,0,1,1,0,7.99306Z" transform="translate(-202.34518 -187.56114)" fill="#ccc"/>
                <path d="M486.09887,688.45968H206.34171a3.99654,3.99654,0,0,1,0-7.99307H486.09887a3.99654,3.99654,0,0,1,0,7.99307Z" transform="translate(-202.34518 -187.56114)" fill="#ccc"/><path d="M653.95317,712.43886H538.05377a3.99653,3.99653,0,0,1,0-7.99306h115.8994a3.99653,3.99653,0,0,1,0,7.99306Z" transform="translate(-202.34518 -187.56114)" fill="#ccc"/>
                <path d="M403.5039,712.43886H287.6045a3.99653,3.99653,0,1,1,0-7.99306H403.5039a3.99653,3.99653,0,0,1,0,7.99306Z" transform="translate(-202.34518 -187.56114)" fill="#ccc"/><path d="M873.76237,688.45968H594.0052a3.99653,3.99653,0,0,1,0-7.99307H873.76237a3.99654,3.99654,0,0,1,0,7.99307Z" transform="translate(-202.34518 -187.56114)" fill="#ccc"/>
                <path d="M993.65829,712.43886H713.90113a3.99653,3.99653,0,0,1,0-7.99306H993.65829a3.99653,3.99653,0,0,1,0,7.99306Z" transform="translate(-202.34518 -187.56114)" fill="#ccc"/>
        </svg>
        </div>
        <h1 className="mt-20 mx-auto max-w-4xl font-display text-4xl font-medium tracking-tight text-slate-900 sm:text-7xl">
        Gjør båtlivet{' '}
        <span className="relative whitespace-nowrap text-blue-600">
          <svg
            aria-hidden="true"
            viewBox="0 0 418 42"
            className="absolute left-0 top-2/3 h-[0.58em] w-full fill-blue-300/70"
            preserveAspectRatio="none"
          >
            <path d="M203.371.916c-26.013-2.078-76.686 1.963-124.73 9.946L67.3 12.749C35.421 18.062 18.2 21.766 6.004 25.934 1.244 27.561.828 27.778.874 28.61c.07 1.214.828 1.121 9.595-1.176 9.072-2.377 17.15-3.92 39.246-7.496C123.565 7.986 157.869 4.492 195.942 5.046c7.461.108 19.25 1.696 19.17 2.582-.107 1.183-7.874 4.31-25.75 10.366-21.992 7.45-35.43 12.534-36.701 13.884-2.173 2.308-.202 4.407 4.442 4.734 2.654.187 3.263.157 15.593-.78 35.401-2.686 57.944-3.488 88.365-3.143 46.327.526 75.721 2.23 130.788 7.584 19.787 1.924 20.814 1.98 24.557 1.332l.066-.011c1.201-.203 1.53-1.825.399-2.335-2.911-1.31-4.893-1.604-22.048-3.261-57.509-5.556-87.871-7.36-132.059-7.842-23.239-.254-33.617-.116-50.627.674-11.629.54-42.371 2.494-46.696 2.967-2.359.259 8.133-3.625 26.504-9.81 23.239-7.825 27.934-10.149 28.304-14.005.417-4.348-3.529-6-16.878-7.066Z" />
          </svg>
          <span className="relative">komfortabelt og stilig</span>
        </span>{' '}
        med vårt premium gulv til båter!
      </h1>
      <p className="mx-auto sm:text-lg mt-6 max-w-2xl text-small tracking-tight text-slate-700">
      Slitesterke, værbestandige og lett-å-installere gulv som tar båtens interiør til et nytt nivå.
      </p>
    </div>
    </div>
  )
}

export default Landing