import React from 'react'
import Landing from '../components/Landing'

export const LandingPage = () => {
  return (
    <div>
        <Landing />
    </div>
  )
}

export default LandingPage